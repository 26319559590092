export enum Abilities {
  READ_CLIENT = 'read_client',
  UPDATE_CLIENT = 'update_client',
  DELETE_REQUEST_LICENSE = 'delete_request_license',
  CREATE_CAMPAIGN = 'create_campaign',
  LIST_ROLE = 'list_role',
  UPDATE_ROLE = 'update_role',
  LIST_CAMPAIGN = 'list_campaign',
  READ_USER = 'read_user',
  LIST_CHARGED_BY_ME_REQUEST_LICENSE = 'list_charged_by_me_request_license',
  CANCEL_REQUEST_LICENSE = 'cancel_request_license',
  READ_CAMPAIGN = 'read_campaign',
  LIST_LICENSE = 'list_license',
  CREATE_OUTBOUND_PLAN = 'create_outbound_plan',
  READ_OUTBOUND_PLAN = 'read_outbound_plan',
  CREATE_ROLE = 'create_role',
  UPDATE_CAMPAIGN = 'update_campaign',
  CREATE_REQUEST_LICENSE = 'create_request_license',
  LIST_TAG = 'list_tag',
  UPLOAD_ATTACHMENT_REQUEST_LICENSE = 'upload_attachment_request_license',
  LIST_USER = 'list_user',
  UPDATE_REQUEST_LICENSE = 'update_request_license',
  CHARGE_ON_BEHALF = 'charge_on_behalf',
  LIST_OUTBOUND_PLAN = 'list_outbound_plan',
  UPDATE_OUTBOUND_PLAN = 'update_outbound_plan',
  LIST_REVISIONS_REQUEST_LICENSE = 'list_revisions_request_license',
  READ_REQUEST_LICENSE = 'read_request_license',
  READ_ROLE = 'read_role',
  UPDATE_USER = 'update_user',
  CREATE_USER = 'create_user',
  LIST_SKILL = 'list_skill',
  CREATE_CLIENT = 'create_client',
  DELETE_CLIENT = 'delete_client',
  LIST_CLIENTS = 'list_clients',
  DELETE_EMPLOYEE = 'delete_employee',
  UPDATE_EMPLOYEE = 'update_employee',
  CREATE_EMPLOYEE = 'create_employee',
}
type Ability = {
  name?: string;
};

export const useHasAbilitiesUser = (availableAbilities: Ability[], hasAbilities: Abilities[]) => {
  const dataToReturn = {
    read_client: false,
    update_client: false,
    delete_request_license: false,
    create_campaign: false,
    list_role: false,
    update_role: false,
    list_campaign: false,
    read_user: false,
    list_charged_by_me_request_license: false,
    cancel_request_license: false,
    read_campaign: false,
    list_license: false,
    create_outbound_plan: false,
    read_outbound_plan: false,
    create_role: false,
    update_campaign: false,
    create_request_license: false,
    list_tag: false,
    upload_attachment_request_license: false,
    list_user: false,
    update_request_license: false,
    charge_on_behalf: false,
    list_outbound_plan: false,
    update_outbound_plan: false,
    list_revisions_request_license: false,
    read_request_license: false,
    read_role: false,
    update_user: false,
    create_user: false,
    list_skill: false,
    create_client: false,
    delete_client: false,
    list_clients: false,
    delete_employee: false,
    update_employee: false,
    create_employee: false,
  };
  const filteredAbilities = availableAbilities.map(ability => ability.name);

  hasAbilities.forEach(ability => {
    dataToReturn[ability] = filteredAbilities.includes(ability);
  });

  return dataToReturn;
};
