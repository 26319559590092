import { differenceInDays, startOfDay } from "date-fns";
import moment from "moment";

export enum FormatDateOutputDash {
  YYYYMMDD = 'YYYY-MM-DD',
  DDMMYYYY = 'DD-MM-YYYY',
  DMYYYY = 'D-M-YYYY',
  YYYYMD = 'YYYY-M-D',
}
export enum FormatDateOutputSlash {
  YYYYMMDD = 'YYYY/MM/DD',
  DDMMYYYY = 'DD/MM/YYYY',
  DMYYYY = 'D/M/YYYY',
  YYYYMD = 'YYYYMD',
}
export enum SpecialFormats {
  WEEKDAYDDMM = 'WEEKDAYDDMM',
  PARSESTRING = 'PARSESTRING',
}

export const dateOnDDMMYYYY = (date: string, splitter: '-' | '/' = '-', divider: '-' | '/' = '/') => {
  const dateISOString = new Date(date).toISOString();
  const dateArray = dateISOString.slice(0, 10).split(splitter);
  return `${dateArray[2]}${divider}${dateArray[1]}${divider}${dateArray[0]}`;
};

export const dateOnMMDDYYYY = (date: string, splitter: '-' | '/' = '-', divider: '-' | '/' = '-') => {
  const dateISOString = new Date(date).toISOString();
  const dateArray = dateISOString.slice(0, 10).split(splitter);

  return `${dateArray[1]}${divider}${dateArray[2]}${divider}${dateArray[0]}`;
};

export const formatDate = (
  date: any,
  formatOutput: FormatDateOutputDash | FormatDateOutputSlash | SpecialFormats,
  offsetMonth = true,
): string | undefined => {
  const d = new Date(date);
  const day: number = d.getDate();
  const month: number = offsetMonth ? d.getMonth() + 1 : d.getMonth();
  const year: number = d.getFullYear();
  const weekdays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  if (formatOutput === FormatDateOutputDash.YYYYMMDD) {
    const _month: string = month < 10 ? `0${month}` : `${month}`;
    const _day: string = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${_month}-${_day}`;
  }
  if (formatOutput === FormatDateOutputDash.DDMMYYYY) {
    const _month: string = month < 10 ? `0${month}` : `${month}`;
    const _day: string = day < 10 ? `0${day}` : `${day}`;
    return `${_day}-${_month}-${year}`;
  }
  if (formatOutput === FormatDateOutputDash.DMYYYY) {
    return `${day}-${month}-${year}`;
  }
  if (formatOutput === FormatDateOutputDash.YYYYMD) {
    return `${year}-${month}-${day}`;
  }

  if (formatOutput === FormatDateOutputSlash.YYYYMMDD) {
    const _month: string = month < 10 ? `0${month}` : `${month}`;
    const _day: string = day < 10 ? `0${day}` : `${day}`;
    return `${year}/${_month}/${_day}`;
  }
  if (formatOutput === FormatDateOutputSlash.DDMMYYYY) {
    const _month: string = month < 10 ? `0${month}` : `${month}`;
    const _day: string = day < 10 ? `0${day}` : `${day}`;
    return `${_day}/${_month}/${year}`;
  }
  if (formatOutput === FormatDateOutputSlash.DMYYYY) {
    return `${day}/${month}/${year}`;
  }
  if (formatOutput === FormatDateOutputSlash.YYYYMD) {
    return `${year}/${month}/${day}`;
  }
  if (formatOutput === SpecialFormats.WEEKDAYDDMM) {
    const spanishWeekDay = weekdays[d.getDay()];
    return `${spanishWeekDay} ${day}/${month}`;
  }
  if (formatOutput === SpecialFormats.PARSESTRING) {
    return date.replace(/-/g, '/').replace(/T.+/, '');
  }
};

/**
 * Returns the number of days between today and the given start date
 *
 * @param {string} startDate - The start date in string format 'YYYY-MM-DD'
 * @param {string} endDate - The end date in string format 'YYYY-MM-DD'
 * @returns {number} The number of days as a string
 */
export const getTakenDays = (startDate: string, endDate: string): number => {
  const today = startOfDay(new Date());
  let daysDifference = 0;

  const parsedStartDate = moment(startDate).toDate();
  const parsedEndDate = moment(endDate).toDate();

  if (parsedStartDate > today) {
    return daysDifference;
  } else if (parsedEndDate < today) {
    daysDifference = Math.round(differenceInDays(parsedEndDate, parsedStartDate) + 1);
    return daysDifference;
  } else {
    daysDifference = Math.round(differenceInDays(today, parsedStartDate) + 1);
    return daysDifference;
  }
};

/**
 * Get the number of days left until the given end date.
 *
 * @param startDate the start date in "MM-DD-YYYY" format
 * @param endDate the end date in "MM-DD-YYYY" format
 * @returns the number of days left until the end date
 */
export const getLeftDays = (startDate: string, endDate: string): number => {
  const today = startOfDay(new Date());
  let daysDifference = 0;

  const parsedStartDate = moment(startDate).toDate();
  const parsedEndDate = moment(endDate).toDate();

  if (parsedEndDate < today) {
    return daysDifference;
  } else if (parsedEndDate > today && today < parsedStartDate) {
    daysDifference = Math.round(differenceInDays(parsedEndDate, parsedStartDate) + 1);
    return daysDifference;
  } else {
    daysDifference = Math.round(differenceInDays(parsedEndDate, today));
    return daysDifference;
  }
};
