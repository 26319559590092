import { httpService } from 'modules/core/services/http.service';
import { getSupervisorsAdapter } from '../adapters';

export const getSupervisors = async () => {
  const res = await httpService.get({
    url: 'users',
    params: {
      fieldsToReturn: 'specific',
    },
  });

  return getSupervisorsAdapter(res.data);
};
