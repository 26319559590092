import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { BreadcrumbsItem } from '../../../layouts/app/breadcrumbs/Breadcrumbs.component';
import { useNavigate } from 'react-router-dom';
import Iconify from '../iconify';

interface AppHeaderProps {
  pageTitle: string;
  headerActions: React.ReactNode;
  ellipsis?: React.ReactNode;
  breadcrumbs?: BreadcrumbsItem[];
  backTo?: string;
}

const AppHeader = ({ headerActions, pageTitle, ellipsis, backTo }: AppHeaderProps) => {
  const navigate = useNavigate();
  return (
    <Container>
      <EllipsisContainer>
        <TextContainer>
          <Button sx={{ minWidth: '20px' }} onClick={() => (backTo ? navigate(backTo) : navigate(-1))}>
            <Iconify icon="eva:arrow-ios-back-fill" color="#000" />
          </Button>
          <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 800, fontSize: 30 }}>
            {pageTitle}
          </Typography>
        </TextContainer>
        <Box>{ellipsis}</Box>
      </EllipsisContainer>
      <Divider />
      {headerActions && (
        <>
          <HeaderContent>
            <HeaderActions>{headerActions}</HeaderActions>
          </HeaderContent>
          <Divider />
        </>
      )}
    </Container>
  );
};

const Container = styled(Box)({
  // marginBottom: '26px',
});

const TextContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const EllipsisContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const HeaderContent = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1),
  width: '100%',
}));

const HeaderActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  margin: '1rem 0',
  gap: theme.spacing(5),
}));

export default AppHeader;
