import Clock from 'assets/icons/configurador/Clock.svg';
import BloodDonation from 'assets/icons/configurador/BloodDonation.svg';
import Cross from 'assets/icons/configurador/Cross.svg';
import Desise from 'assets/icons/configurador/Desise.svg';
import Duel from 'assets/icons/configurador/Duel.svg';
import FamilyDesise from 'assets/icons/configurador/FamilyDesise.svg';
import Marige from 'assets/icons/configurador/Marige.svg';
import Maternity from 'assets/icons/configurador/Maternity.svg';
import Paper from 'assets/icons/configurador/Paper.svg';
import Paternity from 'assets/icons/configurador/Paternity.svg';
import Plane from 'assets/icons/configurador/Plane.svg';
import Shield from 'assets/icons/configurador/Shield.svg';
import Suspension from 'assets/icons/configurador/Suspension.svg';
import Test from 'assets/icons/configurador/Test.svg';
import Tramites from 'assets/icons/configurador/Tramites.svg';
import Truck from 'assets/icons/configurador/Truck.svg';
import { kinshipTypes } from 'modules/requests/interfaces/request.interface';
import {
  ExamRules,
  Seniority,
  Notification,
  VacationRules,
  PositionReservation,
  Excedents,
  DependenciesTypes,
  Birth,
} from './interfaces';

export const getRenderImage = (name: string) => {
  switch (name) {
    case 'Permiso gremial':
      return <img src={Paper} />;
    case 'Suspensión':
      return <img src={Suspension} />;
    case 'Vacaciones':
      return <img src={Plane} />;
    case 'Enfermedad':
      return <img src={Desise} />;
    case 'Enfermedad familiar':
      return <img src={FamilyDesise} />;
    case 'Duelo':
      return <img src={Duel} />;
    case 'Examen':
      return <img src={Test} />;
    case 'Mudanza':
      return <img src={Truck} />;
    case 'Lic. por Maternidad':
      return <img src={Maternity} />;
    case 'Lic. por Paternidad':
      return <img src={Paternity} />;
    case 'Donaciòn de Sangre':
      return <img src={BloodDonation} />;
    case 'Lic sin goce de sueldo':
      return <img src={Cross} />;
    case 'Matrimonio':
      return <img src={Marige} />;
    case 'Reserva de puesto':
      return <img src={Shield} />;
    case 'Trámites o citaciones':
      return <img src={Tramites} />;
    case 'Ausente sin Aviso':
      return <img src={Clock} />;
    default:
      return null;
  }
};

export const REQUIRED_MESSAGE = 'Este campo es obligatorio';

export const kinshipOptions = [
  {
    name: kinshipTypes.SPOUSE,
    days: 0,
  },
  {
    name: kinshipTypes.DAUGHTER_SON,
    days: 0,
  },
  {
    name: kinshipTypes.MOTHER_FATHER,
    days: 0,
  },
  {
    name: kinshipTypes.GRANDPARENTS,
    days: 0,
  },
  {
    name: kinshipTypes.IN_LAWS,
    days: 0,
  },
  {
    name: kinshipTypes.SISTER_BROTHER,
    days: 0,
  },
];

export const seniorityDefault: Seniority[] = [
  {
    from: 0,
    from_unit: '',
    to: 0,
    to_unit: '',
    vacation_days: 0,
    vacation_days_range: 0,
    vacation_days_unit: '',
  },
  {
    from: 0,
    from_unit: '',
    to: 0,
    to_unit: '',
    vacation_days: 0,
    vacation_days_range: 0,
    vacation_days_unit: '',
  },
];

export const vacationRulesDefault: VacationRules[] = [
  {
    days_quantity: 0,
    range_value: 0,
    range_unit: '',
    maximum_days: 0,
  },
  {
    days_quantity: 0,
    range_value: 0,
    range_unit: '',
    maximum_days: 0,
  },
];

export const PositionReservationDefault: PositionReservation[] = [
  {
    from: 0,
    from_unit: '',
    to: 0,
    to_unit: '',
    quantity: 0,
    quantity_unit: '',
  },
  {
    from: 0,
    from_unit: '',
    to: 0,
    to_unit: '',
    quantity: 0,
    quantity_unit: '',
  },
];

export const ExamRulesDefault: ExamRules = {
  days_quantity: 0,
  range_value: 0,
  range_unit: '',
  maximum_days: 0,
};

export const BirthDefault: Birth = {
  id: 0,
  prenatal_days: 45,
  postnatal_days: 45,
};
export const ExcedentsDefault: Excedents = {
  available: false,
  maximum_period: 0,
  value_unit: '',
  renovate_every_value: 0,
  renovate_every_unit: '',
};

export const AbsentDefault: Notification = {
  days_value: 0,
  interval_days: 0,
  summons: 0,
};

export enum periodicityTypes {
  DAYS = 'Día/s',
  WEEKS = 'Semanas',
  MONTHS = 'Meses',
}

export const periodicityOptions = ['Días', 'Meses', 'Años'];

export const periodicityOptionsId = [
  {
    name: periodicityTypes.DAYS,
    id: 'dias',
  },
  {
    name: periodicityTypes.WEEKS,
    id: 'semanas',
  },
  {
    name: periodicityTypes.MONTHS,
    id: 'meses',
  },
];

export const getDependencies = (dependenciesType: DependenciesTypes) => {
  if (dependenciesType) {
    return Object.values(dependenciesType);
  }
  return [];
};
