/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react';
//hooks
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks/store.hooks';
import { useSnackbar } from 'notistack';
import { useAuth } from 'modules/auth/AuthProvider.component';
//actions
import { fetchChargedRequests, fetchRequestRevisions, fetchRequests } from 'store/actions/requests.actions';
//interfaces
import {
  CreateOrEditRequestInterface,
  RequestInterface,
  typeOfSaves,
  RequestsContextType,
  DataModalInterface,
  initialLicenseSelected,
  LicenseInterface,
  OperatorInterface,
  FiltersInterface,
  FilterQueryObjectInterface,
} from './interfaces/request.interface';
import { ColumnTypes } from './interfaces/filtersNew.interface';
//services
import { createRequest, updateAllRequestsStatusSelected, updateRequest } from './services/request.service';
import { fetchLicenses } from 'store/actions/licenses.actions';
//utils
import { format } from 'date-fns';
import { CRUError } from 'modules/shared/interfaces';
import { initialDataModal, mapFiltersToQueryParams } from './utils';
//components
import { TypeModal } from 'modules/shared/components/modal/Modal.component';
import { SelectChangeEvent } from '@mui/material';

const initialFiltersState: FiltersInterface = {
  column: '',
  condition: '',
  value: '',
};

export const RequestsContext = createContext<RequestsContextType>({
  workflowsFormRef: React.createRef<HTMLButtonElement>(),
  WorkflowsValidateForm: () => {},
  dataModal: initialDataModal,
  setDataModal: () => {},
  loading: false,
  setLoading: () => {},
  selectedLicense: initialLicenseSelected,
  setSelectedLicense: () => {},
  handleSave: () => {},
  openCancelModal: () => {},
  closeCancelModal: () => {},
  openDeleteModal: () => {},
  selectedLicenseId: '',
  setSelectedLicenseId: () => {},
  onChangeSelectedModalOptions: () => {},
  openModalToCreateNewRequest: false,
  setOpenModalToCreateNewRequest: () => {},
  openNewResquestModal: () => {},
  operators: [],
  setOperators: () => {},
  workflowId: 0,
  setWorkflowId: () => {},
  // filters
  filters: initialFiltersState,
  setFilters: () => {},
  getDefaultFilters: () => {},
  defaultValuesLoaded: false,
  setDefaultValuesLoaded: () => {},
  queryParams: {},
  setQueryParams: () => {},
  onPageChange: () => {},
  onRowsPerPageChange: () => {},
  approveSelectedRequests: () => {},
  rejectSelectedRequests: () => {},
  selectedRequests: [],
  setSelectedRequests: () => {},
  onSelectRow: () => {},
  onSelectAllRows: () => {},
  areAllRequestsChecked: false,
  setAreAllRequestsChecked: () => {},
  isConfirmQuickActionModalOpen: false,
  setIsConfirmQuickActionModalOpen: () => {},
  handleConfirmQuickAction: () => {},
  currentModalType: 'update',
  setCurrentModalType: () => {},
});

export const useRequestsContext = () => {
  const context = useContext(RequestsContext);
  if (!context) throw new Error('useRequestsContext must be use inside RequestsContextProvider');

  return context;
};

export const RequestsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const licensesOptions = useAppSelector(state => state.licenses.licenses);
  const pagination = useAppSelector(state => state.requests.meta);
  const selectedRequest: RequestInterface = useAppSelector(state => state.requests.selectedRequest);
  // ref
  const workflowsFormRef = React.useRef<HTMLButtonElement>(null);
  //states
  const [loading, setLoading] = useState<boolean>(false);
  const [openModalToCreateNewRequest, setOpenModalToCreateNewRequest] = useState(false);
  const [dataModal, setDataModal] = useState<DataModalInterface>(initialDataModal);
  const [selectedLicense, setSelectedLicense] = useState<LicenseInterface>(initialLicenseSelected);
  const [selectedLicenseId, setSelectedLicenseId] = useState<LicenseInterface | string>('');
  const [operators, setOperators] = useState<OperatorInterface[]>([]);
  const [workflowId, setWorkflowId] = useState<number>(1);
  //filters states
  const [filters, setFilters] = useState<FiltersInterface>(initialFiltersState);
  const [selectedRequests, setSelectedRequests] = useState<string[]>([]);
  const [isSupervisor, setIsSupervisor] = useState<boolean | null>(null);
  const [queryParams, setQueryParams] = useState<FilterQueryObjectInterface>({});
  const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
  const [areAllRequestsChecked, setAreAllRequestsChecked] = useState(false);
  const [isConfirmQuickActionModalOpen, setIsConfirmQuickActionModalOpen] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<'delete' | 'update'>('update');

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = page + 1;
      const queryToSend = { ...queryParams, page: newPage, take: pagination.take };
      if (isSupervisor) {
        dispatch(fetchChargedRequests(queryToSend));
      } else if (user) {
        dispatch(fetchRequests(user.id, queryToSend));
      }
    },
    [queryParams, pagination.take, user],
  );

  const onRowsPerPageChange = useCallback(
    (value: number) => {
      const queryToSend = { ...queryParams, page: 1, take: value };
      if (isSupervisor) {
        dispatch(fetchChargedRequests(queryToSend));
      } else if (user) {
        dispatch(fetchRequests(user.id, queryToSend));
      }
    },
    [queryParams, pagination.take, user],
  );

  const approveSelectedRequests = useCallback(async () => {
    try {
      const updateResults: any = await updateAllRequestsStatusSelected(selectedRequests, '2');
      if (updateResults) {
        const allRequestsApproved: boolean = updateResults.every((item: any) => item.status === 200);
        if (allRequestsApproved) {
          dispatch(fetchChargedRequests());
          enqueueSnackbar('Solicitudes aprobadas correctamente', { variant: 'success' });
        } else {
          enqueueSnackbar('Algunas solicitudes no han sido aprobadas', { variant: 'error' });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedRequests([]);
      setAreAllRequestsChecked(false);
      setIsConfirmQuickActionModalOpen(false);
    }
  }, [selectedRequests]);

  const rejectSelectedRequests = useCallback(async () => {
    try {
      const updateResults: any = await updateAllRequestsStatusSelected(selectedRequests, '3');
      if (updateResults) {
        const allRequestsApproved: boolean = updateResults.every((item: any) => item.status === 200);
        if (allRequestsApproved) {
          dispatch(fetchChargedRequests());
          enqueueSnackbar('Solicitudes rechazadas correctamente', { variant: 'success' });
        } else {
          enqueueSnackbar('Algunas solicitudes no han sido procesadas correctamente', { variant: 'error' });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSelectedRequests([]);
      setAreAllRequestsChecked(false);
      setIsConfirmQuickActionModalOpen(false);
    }
  }, [selectedRequests]);

  const onSelectRow = useCallback(
    (id: string) => {
      setSelectedRequests(selected => {
        if (selected.includes(id)) {
          setAreAllRequestsChecked(false);
          return selected.filter(item => item !== id);
        } else {
          return [...selected, id];
        }
      });
    },
    [selectedRequests],
  );

  const onSelectAllRows = useCallback(
    (checked: boolean, cbFunction: () => void) => {
      setAreAllRequestsChecked(checked);
      cbFunction();
    },
    [areAllRequestsChecked, setAreAllRequestsChecked],
  );

  const handleConfirmQuickAction = useCallback(() => {
    if (currentModalType === 'update') {
      approveSelectedRequests();
    } else if (currentModalType === 'delete') {
      rejectSelectedRequests();
    }
  }, [currentModalType, selectedRequests]);

  const WorkflowsValidateForm = useCallback(() => {
    workflowsFormRef.current?.click();
  }, []);

  const openNewResquestModal = useCallback(async () => {
    if (selectedLicenseId) {
      setOpenModalToCreateNewRequest(false);
      navigate(`/requests/new/${selectedLicenseId}`);
    }
  }, [selectedLicenseId]);

  const fDate = (value: string | null) => {
    if (value) {
      const date = new Date(value);
      const formattedDate = format(date, 'yyyy-MM-dd');
      return formattedDate;
    }
    return null;
  };

  const handleSave = useCallback(async (form: CreateOrEditRequestInterface, type: typeOfSaves) => {
    if (type === typeOfSaves.CREATE) {
      try {
        setLoading(true);
        const {
          license,
          start_date,
          end_date,
          notes,
          operator,
          supervisor,
          kinship,
          diagnosis,
          date_of_birth,
          maternity_license_type,
          partner_name,
          partner_document,
          reason,
          formal_comunication_sent: formal_comunication_sent,
          acknowledgement: acknowledgement,
        } = form;
        const formattedLicense = { id: license.id, name: license.name, active: license.active };
        const formattedOperator = { id: operator.id, firstname: operator.firstname, lastname: operator.lastname };
        const response: any = await createRequest({
          license: formattedLicense,
          operator: formattedOperator,
          start_date: fDate(start_date),
          end_date: fDate(end_date),
          notes,
          supervisor: supervisor,
          kinship,
          partner_name,
          diagnosis,
          date_of_birth,
          maternity_license_type,
          partner_document,
          reason,
          formal_comunication_sent: formal_comunication_sent,
          acknowledgement: acknowledgement,
        });
        navigate(`/requests/${response.id}`);
        enqueueSnackbar('Solicitud creada correctamente');
      } catch (error) {
        const typedError = error as CRUError;
        setDataModal({
          isOpenModal: true,
          title: 'Ha ocurrido un error',
          content: typedError?.response.data.message.message,
          loadingBtnConfirm: false,
          typeModal: TypeModal.ERROR,
          textBtnConfirm: 'Aceptar',
          hasError: true,
        });
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const {
          id,
          license,
          start_date,
          end_date,
          notes,
          operator,
          supervisor,
          kinship,
          diagnosis,
          date_of_birth,
          maternity_license_type,
          partner_name,
          partner_document,
          reason,
          formal_comunication_sent,
          acknowledgement,
        } = form;
        if (!id) return;
        const formattedLicense = { id: license.id, name: license.name, active: license.active };
        const formattedOperator = {
          firstname: operator.firstname || operator.names,
          id: operator.id,
          lastname: operator.lastname || operator.surenames,
        };

        await updateRequest(id, {
          license: formattedLicense,
          start_date: fDate(start_date),
          end_date: fDate(end_date),
          notes,
          operator: formattedOperator,
          supervisor,
          kinship,
          diagnosis,
          date_of_birth,
          maternity_license_type,
          partner_name,
          partner_document,
          reason,
          formal_comunication_sent: formal_comunication_sent,
          acknowledgement: acknowledgement,
        });
        navigate(`/requests`);
        enqueueSnackbar('Cambios guardados correctamente');
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const openCancelModal = useCallback(() => {
    setDataModal({
      isOpenModal: true,
      title: 'Anular Novedad',
      content: `¿Desea anular la novedad ${selectedRequest.license.name} ${selectedRequest.start_date} de forma permanente?`,
      loadingBtnConfirm: false,
      typeModal: TypeModal.ERROR,
      textBtnConfirm: 'Anular',
      hasError: false,
    });
  }, []);

  const closeCancelModal = useCallback(() => {
    setDataModal({
      isOpenModal: false,
      title: '',
      content: '',
      loadingBtnConfirm: false,
      typeModal: TypeModal.ERROR,
      textBtnConfirm: '',
      hasError: false,
    });
  }, []);

  const openDeleteModal = useCallback(() => {
    setDataModal({
      isOpenModal: true,
      title: 'Eliminar Novedad',
      content: `¿Desea eliminar la novedad ${selectedRequest.license.name} ${selectedRequest.start_date} de forma permanente?`,
      loadingBtnConfirm: false,
      typeModal: TypeModal.ERROR,
      textBtnConfirm: 'Eliminar',
      hasError: false,
    });
  }, []);

  const getDefaultFilters = useCallback(() => {
    if (filters.column !== '') {
      switch (filters.column) {
        case ColumnTypes.ALL_CHARGED:
          dispatch(fetchChargedRequests());
          break;
        case ColumnTypes.ALL_REVISIONS:
          dispatch(fetchRequestRevisions());
          break;
        case ColumnTypes.MY_REQUESTS:
          dispatch(fetchRequests(user ? user.employee.id : ''));
          break;
      }
    }
    setDefaultValuesLoaded(true);
  }, [filters.column]);

  const getRequests = useCallback(() => {
    if (defaultValuesLoaded) {
      const params: FilterQueryObjectInterface = mapFiltersToQueryParams(filters);
      setQueryParams(params);

      if (isSupervisor) {
        if (
          filters.column === ColumnTypes.ALL_CHARGED ||
          (filters.column === ColumnTypes.EMPLOYEE && filters.condition !== '') ||
          (filters.column === ColumnTypes.CERTIFICATE_STATUS && filters.condition !== '') ||
          (filters.column === ColumnTypes.REQUEST_STATUS && filters.condition !== '') ||
          (filters.column === ColumnTypes.REQUEST_TYPE && filters.condition !== '') ||
          (filters.column === ColumnTypes.DATE && filters.condition !== '')
        ) {
          dispatch(fetchChargedRequests(params));
        } else if (filters.column === ColumnTypes.ALL_REVISIONS) {
          dispatch(fetchRequestRevisions(params));
        } else if (filters.column === ColumnTypes.MY_REQUESTS) {
          dispatch(fetchRequests(user ? user.employee.id : '', params));
        }
      } else {
        dispatch(fetchRequests(user ? user.employee.id : '', params));
      }
    }
  }, [filters]);

  const onChangeSelectedModalOptions = useCallback((e: SelectChangeEvent<unknown>) => {
    setSelectedLicenseId(e.target.value as LicenseInterface);
    const selectedLicense = licensesOptions.find(license => license.id === e.target.value);
    selectedLicense && setSelectedLicense(selectedLicense);
  }, []);

  useEffect(() => {
    if (user && licensesOptions.length <= 0) {
      dispatch(fetchLicenses());
      setIsSupervisor(user?.role.see_all_charged_licenses ? true : false);
    }
  }, [user]);

  useEffect(() => {
    if (!defaultValuesLoaded && filters.column !== '') {
      getDefaultFilters();
    }
  }, [filters.column]);

  useEffect(() => {
    getRequests();
  }, [filters]);

  const memoizedValue = useMemo(
    () => ({
      workflowsFormRef,
      WorkflowsValidateForm,
      dataModal,
      setDataModal,
      loading,
      setLoading,
      selectedLicense,
      setSelectedLicense,
      handleSave,
      openCancelModal,
      closeCancelModal,
      openDeleteModal,
      selectedLicenseId,
      setSelectedLicenseId,
      onChangeSelectedModalOptions,
      openModalToCreateNewRequest,
      setOpenModalToCreateNewRequest,
      openNewResquestModal,
      operators,
      setOperators,
      workflowId,
      setWorkflowId,
      filters,
      setFilters,
      onPageChange,
      onRowsPerPageChange,
      queryParams,
      setQueryParams,
      defaultValuesLoaded,
      setDefaultValuesLoaded,
      getDefaultFilters,
      approveSelectedRequests,
      rejectSelectedRequests,
      selectedRequests,
      setSelectedRequests,
      onSelectRow,
      onSelectAllRows,
      areAllRequestsChecked,
      setAreAllRequestsChecked,
      isConfirmQuickActionModalOpen,
      setIsConfirmQuickActionModalOpen,
      handleConfirmQuickAction,
      currentModalType,
      setCurrentModalType,
    }),
    [
      workflowsFormRef,
      WorkflowsValidateForm,
      dataModal,
      setDataModal,
      loading,
      setLoading,
      selectedLicense,
      setSelectedLicense,
      handleSave,
      openCancelModal,
      closeCancelModal,
      openDeleteModal,
      selectedLicenseId,
      setSelectedLicenseId,
      onChangeSelectedModalOptions,
      openModalToCreateNewRequest,
      setOpenModalToCreateNewRequest,
      openNewResquestModal,
      operators,
      setOperators,
      workflowId,
      setWorkflowId,
      filters,
      setFilters,
      onPageChange,
      onRowsPerPageChange,
      queryParams,
      setQueryParams,
      defaultValuesLoaded,
      setDefaultValuesLoaded,
      getDefaultFilters,
      approveSelectedRequests,
      rejectSelectedRequests,
      selectedRequests,
      setSelectedRequests,
      onSelectRow,
      onSelectAllRows,
      areAllRequestsChecked,
      setAreAllRequestsChecked,
      isConfirmQuickActionModalOpen,
      setIsConfirmQuickActionModalOpen,
      handleConfirmQuickAction,
      currentModalType,
      setCurrentModalType,
    ],
  );
  return <RequestsContext.Provider value={memoizedValue}>{children}</RequestsContext.Provider>;
};
