import { AppDispatch } from 'store';
import { campaingActions } from 'store/slices/campaigns.slices';
// import { CampaignInterface } from 'modules/campaigns/interfaces/campaigns.interface';

// services
import { getCampaigns, getCampaignById, IdCampaignType, getSkills, getTags } from 'modules/campaigns/services';

// shared services
import { getSupervisors } from 'modules/shared/services';

import { formatDate, FormatDateOutputDash } from 'utils';

type queryParamsType = {
  searchBy: string;
  searchText: string;
  startDateFrom?: string;
  startDateTo?: string;
  page?: number;
  take?: number;
};

type QueryToSend = {
  value?: string;
  field?: string;
  start_date?: string;
  end_date?: string;
  page?: number;
  take?: number;
};
export const fetchCampaigns = (queryParams?: queryParamsType) => {
  return async (dispatch: AppDispatch) => {
    try {
      let result;
      const queryToSend: QueryToSend = {};

      dispatch(campaingActions.setLoading(true));

      if (queryParams?.take) queryToSend.take = queryParams.take;
      if (queryParams?.page) queryToSend.page = queryParams.page;

      if (queryParams?.searchBy && queryParams?.searchText) {
        const { searchBy, searchText } = queryParams;

        queryToSend.value = searchText;

        if (searchBy === 'campaign_name') queryToSend.field = 'name';
        if (searchBy === 'client') queryToSend.field = 'client';
        if (searchBy === 'supervisor') queryToSend.field = 'supervisor';
        if (searchBy === 'campaign_type') queryToSend.field = 'type';
        if (searchBy === 'campaign_tags') queryToSend.field = 'tags';
      }
      if (queryParams?.searchBy && queryParams.startDateFrom && queryParams.startDateTo) {
        const { searchBy, startDateFrom, startDateTo } = queryParams;

        if (searchBy === 'start_date') {
          queryToSend.start_date = formatDate(startDateFrom, FormatDateOutputDash.YYYYMMDD);
          queryToSend.end_date = formatDate(startDateTo, FormatDateOutputDash.YYYYMMDD);
        }
      }
      if (Object.keys(queryToSend).length) {
        result = await getCampaigns(queryToSend);
      } else {
        result = await getCampaigns();
      }

      dispatch(campaingActions.setCampaings(result.campaigns));
      dispatch(campaingActions.setMeta(result.meta));
    } catch (error) {
      dispatch(campaingActions.setCampaings([]));
    } finally {
      dispatch(campaingActions.setLoading(false));
    }
  };
};

export const fetchCampaignById = (campaignIdParam: IdCampaignType) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(campaingActions.setLoading(true));
      const result = await getCampaignById(campaignIdParam);

      dispatch(campaingActions.setSelectedCampaign(result));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(campaingActions.setLoading(false));
    }
  };
};

export const fetchSkills = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const result = await getSkills();

      dispatch(campaingActions.setListSkills(result));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchTags = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const result = await getTags();

      dispatch(campaingActions.setListTags(result));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchSupervisors = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const result = await getSupervisors();

      dispatch(campaingActions.setListSupervisors(result));
    } catch (error) {
      console.log(error);
    }
  };
};

export const resetMeta = () => {
  return (dispatch: AppDispatch) => {
    dispatch(campaingActions.resetMeta());
  };
};
