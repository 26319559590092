import * as React from 'react';
import { useNavigate } from 'react-router-dom';
//components
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, ListItemButton, ListItemText } from '@mui/material';

export default function Settings() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = () => {
    navigate('/setup-configuration');
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} onClick={openMenu} sx={{ minWidth: 'initial' }}>
        <SettingsIcon sx={{ color: '#0e48a3' }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root.MuiPopover-paper': {
            borderRadius: '4px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -100,
        }}
      >
        <Box sx={{ p: 2 }}>
          <ListItemButton
            onClick={() => onMenuItemClick()}
            sx={{ color: '#0e48a3', textTransform: 'initial', fontWeight: 600 }}
          >
            <ListItemText primary="Configuración" />
          </ListItemButton>
        </Box>
      </Popover>
    </div>
  );
}
