export type Tag = {
  id?: string;
  name: string;
  category?: {
    id?: string;
    name: string;
  };
};

export type Skill = {
  id?: string;
  name: string;
  category?: {
    id?: string;
    name: string;
  };
};
export type Supervisor = {
  id?: string;
  firstname: string;
  lastname: string;
};
export interface CampaignInterface {
  created_at: string;
  id: string;
  start_date: string | null;
  platform_id: string;
  crm: string | null;
  name: string;
  type: string;
  client: {
    name: string;
  };
  tags: Tag[];
  supervisors: Supervisor[]; // is user type
}

export interface SelectedCampaignInterface extends CampaignInterface {
  features: string;
  status: boolean;
  requirements: string;
  start_date: string | null;
  platform_id: string;
  crm: string | null;
  hardTarget?: {
    dashboard_start_date?: string;
    dashboard_target_effective_calls?: number;
  };
  hardTargetsHistory?: {
    dashboard_start_date?: string;
    dashboard_target_effective_calls?: number;
    updated_at?: string;
  }[];
  client: {
    id: string;
    name: string;
  };
  tags: Tag[];
  skills: Skill[];
}

export const campaingModel: CampaignInterface = {
  created_at: '',
  id: '',
  name: '',
  start_date: '',
  platform_id: '',
  crm: null,
  type: '',
  client: {
    name: '',
  },
  tags: [{ name: '', category: { id: '', name: '' } }],
  supervisors: [{ firstname: '', lastname: '' }], // is user type
};

export const selectedCampaignModel: SelectedCampaignInterface = {
  created_at: '',
  id: '',
  name: '',
  start_date: '',
  platform_id: '',
  crm: null,
  type: '',
  client: {
    id: '',
    name: '',
  },
  tags: [{ id: '', name: '', category: { id: '', name: '' } }],
  supervisors: [{ firstname: '', lastname: '' }], // is user type
  features: '',
  status: false,
  requirements: '',
  skills: [{ id: '', name: '', category: { id: '', name: '' } }],
  hardTarget: {
    dashboard_start_date: '',
    dashboard_target_effective_calls: undefined,
  },
  hardTargetsHistory: [{ dashboard_start_date: '', dashboard_target_effective_calls: undefined, updated_at: '' }],
};

export const emptyCampaignModel: SelectedCampaignInterface = {
  created_at: '',
  id: '',
  name: '',
  start_date: '',
  platform_id: '',
  crm: null,
  features: '',
  type: '',
  status: false,
  requirements: '',
  client: {
    id: '',
    name: '',
  },
  tags: [],
  skills: [],
  supervisors: [],
  hardTarget: {
    dashboard_start_date: '',
    dashboard_target_effective_calls: undefined,
  },
};

export type Meta = {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export enum ConfigTabDisplay {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export interface HardTargetsHistoryProps {
  dashboard_start_date?: string;
  dashboard_target_effective_calls?: number;
  updated_at?: string;
}
