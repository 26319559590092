import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BirthdayInterface,
  campaignInDashboardInterface,
  Dashboard,
  DashboardSliceInterface,
  emptySelectedCampaign,
  HardTarget,
} from 'modules/home/interfaces';
import { Meta } from 'modules/shared/interfaces';
import { emptyDashboardUserModel } from 'modules/users/interfaces/users.interface';

const emptyDashboard: Dashboard = {
  requestUser: {
    data: [],
  },
  supervised_user_requests: {
    data: [],
  },
  user: emptyDashboardUserModel,
};

export const initialState: DashboardSliceInterface = {
  loading: false,
  data: emptyDashboard,
  selectedCampaign: emptySelectedCampaign,
  hardTarget: '',
  campaigns: [],
  birthdays: {
    data: [],
    meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setDashboards(state, action: PayloadAction<Dashboard>) {
      state.data = action.payload;
    },
    setSelectedCampaign(state, action: PayloadAction<campaignInDashboardInterface>) {
      state.selectedCampaign = action.payload;
    },
    setCampaigns(state, action: PayloadAction<campaignInDashboardInterface[]>) {
      state.campaigns = action.payload;
    },
    setHardTargets(state, action: PayloadAction<HardTarget>) {
      state.hardTarget = action.payload;
    },
    setBirthdays(state, action: PayloadAction<{ data: BirthdayInterface[]; meta: Meta }>) {
      state.birthdays = action.payload;
    },
  },
});
export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
