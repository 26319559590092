import { useState } from 'react';
import { IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import AppMenu from './components/appMenu/AppMenu.component';
import Header from './components/header/Header.component';
import { Outlet } from 'react-router-dom';
import { useRef } from 'react';
import Iconify from 'modules/shared/components/iconify';

const AppLayout = () => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef<null | HTMLDivElement>(null);

  const toggleMenu = () => {
    setToggle(prevState => !prevState);
    if (matchSizeSmall) {
      handleUpClick();
    }
  };
  const theme = useTheme();
  const matchSizeSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const handleUpClick = () => {
    ref.current?.scrollIntoView({ behavior: 'auto' });
  };

  return (
    <AppContainer ref={ref} sx={{ background: '#f8f8f8' }}>
      <HeaderContainer>
        <Header onMenuClick={toggleMenu} expand={toggle} />
      </HeaderContainer>
      <AppContent>
        <MenuContainer matchSizeSmall={matchSizeSmall} expand={toggle}>
          <AppMenu onSelection={toggleMenu} expand={toggle} setExpand={setToggle} onArrowClick={handleUpClick} />
        </MenuContainer>
        <StyledIconButton onClick={toggleMenu}>
          <StyledDivBorder />
          {toggle ? (
            <Iconify icon="mdi:chevron-left" color="#fff" sx={{ width: 30, height: 30 }} />
          ) : (
            <Iconify icon="mdi:chevron-right" color="#fff" sx={{ width: 30, height: 30 }} />
          )}
        </StyledIconButton>
        <ArticleContainer
          sx={{
            ...(matchSizeSmall && toggle ? { paddingLeft: '5px' } : {}),
            ...(matchSizeSmall ? { paddingLeft: '1.5rem' } : { paddingLeft: '2rem' }),
          }}
        >
          <Children>
            <Outlet />
          </Children>
        </ArticleContainer>
      </AppContent>
    </AppContainer>
  );
};

const StyledDivBorder = styled('div')(() => ({
  height: '66px',
  width: '8px',
  backgroundColor: '#4A90FF',
  position: 'absolute',
  left: '1px',
  '&::before': {
    backgroundColor: '#F8F8F8',
    content: '"some content"',
    fontSize: '0px',
    width: '8px',
    display: 'flex',
    height: '8px',
    position: 'absolute',
    left: '0',
    borderBottomLeftRadius: '8px',
    //borderRadius: '0 100px 0 0',
  },
  '&::after': {
    content: '"some content"',
    fontSize: '0px',
    backgroundColor: '#F8F8F8',
    width: '8px',
    display: 'flex',
    height: '8px',
    position: 'absolute',
    marginTop: '58px',
    borderTopLeftRadius: '8px',
    left: '0',
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: 'sticky',
  top: 140,
  height: '50px',
  width: '35px',
  borderRadius: '0 8px 8px 0',
  backgroundColor: '#4A90FF',
  '&:hover': {
    backgroundColor: '#4A90FF',
  },
}));

const AppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  background: theme.palette.grey[800],
}));

const HeaderContainer = styled('div')(() => ({
  position: 'relative',
}));

const AppContent = styled('div')({
  flex: 1,
  display: 'flex',
});

const ArticleContainer = styled('div')(({ theme }) => ({
  flex: 1,
  minWidth: 200,
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(6),
  margin: 'auto',
  maxWidth: '100%',
}));

const Children = styled('div')(({ theme }) => ({
  flex: 1,
  minHeight: `calc(100vh - 114px)`,
  marginBottom: theme.spacing(0), // necessary to add space to bottom paper.
}));

const MenuContainer = styled('div', { shouldForwardProp: props => props !== 'expand' && props !== 'matchSizeSmall' })(
  ({ expand, matchSizeSmall }: { expand: boolean; matchSizeSmall: boolean }) => ({
    height: `calc(100vh - 80px)`,
    position: 'sticky',
    top: 80,
    transition: 'all 250ms',
    minWidth: expand && matchSizeSmall ? 180 : expand ? 256 : matchSizeSmall ? 1 : 64,
  }),
);

export default AppLayout;
