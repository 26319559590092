import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box } from '@mui/material';

interface IconProps {
  size?: number;
  statusColor?: 'warning' | 'error';
  sx?: object;
}

export const ThumbUpWithDot = ({ size, statusColor, sx }: IconProps) => {
  const getColor = (theme: any) => {
    switch (statusColor) {
      case 'error':
        return theme.palette.error.main;
      case 'warning':
        return theme.palette.warning.main;
      default:
        return theme.palette.success.main;
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <ThumbUpIcon sx={{ fontSize: `${size}px`, color: theme => theme.palette.secondary.main || 'secondary', ...sx }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          right: 0,
          bottom: 0,
          zIndex: 1,
          backgroundColor: '#E7EFED',
          width: '20px',
          height: '20px',
          borderRadius: '50%',
        }}
      >
        <Box
          sx={{
            right: 0,
            bottom: 0,
            zIndex: 1,
            backgroundColor: theme => getColor(theme),
            width: '16px',
            height: '16px',
            borderRadius: '50%',
          }}
        ></Box>
      </Box>
    </Box>
  );
};
