import { httpService } from 'modules/core/services/http.service';
import getCampaignsAdapter from '../adapters/getCampaigns.adapter';

export const getCampaigns = async () => {
  const res = await httpService.get({
    url: 'campaigns/all',
  });

  return getCampaignsAdapter(res.data);
};
