import { httpService } from 'modules/core/services/http.service';

export const uploadFile = async (id: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const res = await httpService.post({
    url: `employees/${id}/image`,
    payload: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  return res;
};

const getFileURL = process.env.REACT_APP_API_URL;

export const getFile = async (name: string, token: string) => {
  const fileData = await fetch(`${getFileURL}/employees/${name}/image`, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
    }),
  });
  if (!fileData.ok) {
    throw new Error(`Failed to fetch image: ${fileData.status}`);
  }

  const blob = await fileData.blob();

  const file = new File([blob], name, { type: blob.type });

  return file;
};
