import { Components } from '@mui/material';
import foundations from '../foundations';

const { colors } = foundations;

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      borderRadius: 5,
    },
  },
  variants: [
    {
      props: { color: 'primary' },
      style: {
        minWidth: '140px',
        backgroundColor: '#4A90FF',
        '&:hover': {
          backgroundColor: '#7AAAF8',
        },
      },
    },
    {
      props: { color: 'secondary' },
      style: {
        minWidth: '140px',
        color: '#4A90FF',
        fontWeight: 600,
        backgroundColor: 'transparent',
        border: '1px solid #4A90FF',
        '&:hover': {
          backgroundColor: '#7AAAF8',
        },
      },
    },
    {
      props: { color: 'info' },
      style: {
        backgroundColor: '#0E48A3',
        '&:hover': {
          backgroundColor: '#1463E0',
          boxShadow: '0px 5px 10px 0px #00000026 !important',
        },
      },
    },
    {
      props: { color: 'info', variant: 'outlined' },
      style: {
        color: '#4A90FF',
        minWidth: '140px',
        borderColor: '#4A90FF',
        fontWeight: 600,
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#DFEBFF',
          boxShadow: 'none !important',
        },
      },
    },
    {
      props: { color: 'warning' },
      style: {
        backgroundColor: colors.brandColors.warningPrimary,
        '&:hover': {
          backgroundColor: colors.brandColors.warningHover,
        },
      },
    },
    {
      props: { variant: 'text' },
      style: {
        backgroundColor: 'transparent',
        borderColor: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: colors.brandColors.simpleGrey,
        },
      },
    },
    {
      props: { variant: 'outlined' },
      style: {
        border: '1px solid #4A90FF',
        backgroundColor: 'transparent',
        borderColor: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: colors.brandColors.simpleGrey,
        },
      },
    },
    {
      props: { variant: 'contained' },
      style: {
        fontWeight: 'bold',
        color: 'white',
        paddingLeft: 22,
        paddingRight: 22,
      },
    },
    {
      props: { size: 'small' },
      style: {
        height: 37,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        height: 37,
      },
    },
    {
      props: { size: 'large' },
      style: {
        height: 48,
      },
    },
  ],
};
