const getUsersAdapter = (data: any) => {
  let dataToReturn = {
    users: [],
    meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
  };

  if (data?.data && data?.meta) {
    dataToReturn = {
      users: data.data,
      meta: data.meta,
    };
  } else {
    dataToReturn = {
      users: data,
      meta: { page: 1, take: 25, itemCount: 0, pageCount: 0, hasPreviousPage: false, hasNextPage: false },
    };
  }

  return dataToReturn;
};

export default getUsersAdapter;
