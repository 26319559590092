import { InboundCalculationData } from 'modules/inbound-calculator/services/inboundCalculator.service';
import { createContext, ReactNode, useContext, useState } from 'react';

export enum Periods {
  MINUTES_15 = 15,
  MINUTES_30 = 30,
  MINUTES_60 = 60,
}

export interface CalculatorData {
  campaign: string;
  incomingContacts: number;
  periodContacts: Periods;
  averageInteractionTime: number;
  serviceLevelRequired: number;
  responseTime: number;
  maxOccupation?: number;
  reducers?: number;
  averagePatience?: number;
  weeklyHours?: number;
}

export interface SelectedRowData extends InboundCalculationData {
  name: string;
}

interface InboundCalculatorContextProps {
  selectedRowData: SelectedRowData | null;
  suggestedRowData: InboundCalculationData | null;
  calculatorData: CalculatorData;
  setCalculatorFields: (params: CalculatorData) => void;
  resetCalculatorFields: () => void;
  setSelectedRow: (row: SelectedRowData | null) => void;
  setSuggestedRow: (row: InboundCalculationData | null) => void;
}

interface InboundCalculatorProviderProps {
  children: ReactNode;
}

export const defualtCalculatorData: CalculatorData = {
  campaign: '',
  incomingContacts: 400,
  periodContacts: Periods.MINUTES_30,
  averageInteractionTime: 257,
  serviceLevelRequired: 80,
  responseTime: 20,
  maxOccupation: 85,
  reducers: 30,
  averagePatience: 60,
  weeklyHours: 37.5,
};

const InboundCalculatorContext = createContext<InboundCalculatorContextProps>({
  selectedRowData: null,
  suggestedRowData: null,
  calculatorData: defualtCalculatorData,
  resetCalculatorFields: () => null,
  setCalculatorFields: () => null,
  setSelectedRow: () => null,
  setSuggestedRow: () => null,
});

export const InboundCalculatorProvider = ({ children }: InboundCalculatorProviderProps) => {
  const [calculatorData, setCalculatorData] = useState<CalculatorData>(defualtCalculatorData);
  const [selectedRowData, setSelectedRowData] = useState<SelectedRowData | null>(null);
  const [suggestedRowData, setSuggestedRowData] = useState<InboundCalculationData | null>(null);

  const setCalculatorFields = (params: CalculatorData) => {
    setCalculatorData(params);
  };

  const resetCalculatorFields = () => setCalculatorFields(defualtCalculatorData);

  const setSelectedRow = (row: SelectedRowData | null) => setSelectedRowData(row);

  const setSuggestedRow = (row: InboundCalculationData | null) => setSuggestedRowData(row);

  return (
    <InboundCalculatorContext.Provider
      value={{
        calculatorData,
        selectedRowData,
        suggestedRowData,
        setCalculatorFields,
        resetCalculatorFields,
        setSelectedRow,
        setSuggestedRow,
      }}
    >
      {children}
    </InboundCalculatorContext.Provider>
  );
};

export const useInboundCalculator = () => useContext(InboundCalculatorContext);
