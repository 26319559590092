import { Chip, Tooltip } from '@mui/material';
//icons
import { CertifIcon } from 'modules/mui/theme/components/CustomIcons';
import Check from 'assets/icons/AccRapidaAprobado.svg';
import Reject from 'assets/icons/Rechazado.svg';
import Pending from 'assets/icons/Pendiente.svg';

export const getStatusIcon = (statusId: number) => {
  switch (statusId) {
    case 4:
    case 7:
    case 5:
      return (
        <Tooltip title="Justificativo pendiente">
          <span>
            <CertifIcon sx={{ stroke: '#333', opacity: 0.3 }} />
          </span>
        </Tooltip>
      );
    case 6:
      return (
        <Tooltip title="Justificativo en revisión">
          <span>
            <CertifIcon sx={{ stroke: '#eec280' }} />
          </span>
        </Tooltip>
      );
    case 8:
    case 10:
      return (
        <Tooltip title="Justificativo aprobado">
          <span>
            <CertifIcon sx={{ stroke: '#85c085' }} />
          </span>
        </Tooltip>
      );
    case 9:
    case 12:
      return (
        <Tooltip title="Justificativo rechazado">
          <span>
            <CertifIcon sx={{ stroke: '#F09184' }} />
          </span>
        </Tooltip>
      );
    default:
      return <>-</>;
  }
};

export const getStatusImage = (statusId: number) => {
  if (statusId === 5) {
    return (
      <Tooltip title="Solicitud pendiente">
        <span>
          <img src={Pending} style={{ width: '25px' }} />
        </span>
      </Tooltip>
    );
  } else if ([4, 6, 7, 2, 10].includes(statusId)) {
    return (
      <Tooltip title="Solicitud aprobada">
        <span>
          <img src={Check} style={{ width: '25px' }} />
        </span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Solicitud rechazada">
        <span>
          <img src={Reject} style={{ width: '25px' }} />
        </span>
      </Tooltip>
    );
  }
};

export const getStatusChip = (statusId: number) => {
  if (statusId === 5) {
    return <Chip sx={{ backgroundColor: '#eec280' }} label="Pendiente"></Chip>;
  } else if (statusId === 4 || statusId === 6 || statusId === 7 || statusId === 2 || statusId === 10) {
    return <Chip sx={{ backgroundColor: '#85c085' }} label="Aprobada"></Chip>;
  } else {
    return <Chip sx={{ backgroundColor: '#F09184' }} label="Rechazada"></Chip>;
  }
};